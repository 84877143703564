<template>
  <Disclosure as="nav" class="bg-gray-900 fixed z-50 w-full" v-slot="{ open }">
    <div class="mx-auto px-8 sm:px-6 lg:px-16">
      <div class="flex items-center justify-between h-14">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <img
              class="h-5"
              src="../assets/logo/gordian_white.svg"
              loading="eager"
              alt="Gordian Software logo"
            />
          </div>
          <div class="hidden xl:block">
            <div class="ml-6 flex items-baseline space-x-1 text-gray-300">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                class="hover:text-white px-2 py-2 rounded-md text-sm font-medium"
                active-class="text-white"
                >{{ item.name }}</router-link
              >
              <router-link
                v-if="showReports"
                key="report"
                to="/report"
                class="hover:text-white px-2 py-2 rounded-md text-sm font-medium"
                active-class="text-white"
                >Reports</router-link
              >
            </div>
          </div>
        </div>
        <div class="flex-1 flex justify-end ml-5">
          <!-- Search Bar -->
          <div class="flex-1 items-center mr-3 hidden md:block relative">
            <input
              spellcheck="false"
              class="form-input w-full py-2 px-3 rounded-lg bg-opacity-10 bg-white border-none focus:ring-0 focus:bg-opacity-90 transition-colors text-sm text-white focus:text-gray-700 placeholder-gray-400 placeholder-sm"
              type="text"
              placeholder="Find Trips and Sessions by ID or PNR"
              v-model="searchQuery"
              v-on:keyup.enter="search"
            />
            <!-- Search Results -->
            <div
              v-if="searchResults.length > 0 || searchLoading || error"
              ref="searchResultsRef"
              class="w-full rounded shadow-md absolute top-10 bg-white overflow-hidden flex flex-col justify-center align-center"
            >
              <div
                class="loader ease-linear rounded-full border-2 border-t-3 border-gray-200 h-4 w-4 m-3"
                v-if="searchLoading"
              ></div>
              <p v-if="error" class="text-red-300 py-2 px-3">{{ error }}</p>
              <div
                v-for="trip in searchedTrips"
                :key="trip.tripId"
                class="flex justify-start w-full align-center cursor-pointer hover:bg-gray-100 py-2 px-3"
                @click="goToTrip(trip.tripId as string)"
              >
                <p class="mr-2">Trip:</p>
                <p class="text-gray-500">{{ trip.tripId }}</p>
                <template v-if="trip.reservationId">
                  <p class="mr-2 ml-2">Reservation ID:</p>
                  <p class="text-gray-500">{{ trip.reservationId }}</p>
                </template>
              </div>
              <div
                v-for="session in searchedSessions"
                :key="session.id"
                class="flex justify-start w-full align-center cursor-pointer hover:bg-gray-100 py-2 px-3"
                @click="goToSession(session.id as number)"
              >
                <p class="mr-2">Session:</p>
                <p class="text-gray-500">{{ session.id }}</p>
              </div>
            </div>
          </div>
          <!-- Integration Select -->
          <div class="flex items-center w-60 md:w-40 xl:w-60 mr-4">
            <Listbox as="div" v-model="sharedState.integration" class="w-full">
              <div class="relative">
                <ListboxButton
                  class="form-input w-full bg-opacity-10 bg-white border-none rounded-lg pl-1 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-0 text-sm text-gray-400"
                >
                  <span class="flex items-center">
                    <span class="ml-2 block truncate">
                      <span
                        class="px-1 text-xs font-semibold rounded border text-gray-400 border-gray-400 mr-0.5"
                      >
                        {{ sharedState.integration.type }}
                      </span>
                      {{
                        sharedState.integration.displayName ||
                        sharedState.integration.integrationName
                      }}
                    </span>
                  </span>
                  <span
                    class="ml-1 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>
                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-0 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    v-if="sharedState.integrations"
                  >
                    <ListboxOption
                      as="template"
                      v-for="integration in sharedState.integrations"
                      :key="integration.id"
                      :value="integration"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          active ? 'bg-gray-200' : 'text-gray-900',
                          'cursor-default select-none relative py-2 px-0.5 text-sm',
                        ]"
                      >
                        <div class="flex items-center">
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate',
                            ]"
                          >
                            <span
                              class="px-1 text-xs font-semibold rounded border bg-blue-100 text-blue-800 border-blue-800 mr-0.5"
                            >
                              {{ integration.type }}
                            </span>
                            {{
                              integration.displayName ||
                              integration.integrationName
                            }}
                          </span>
                        </div>
                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-auto relative hidden xl:block">
            <div class="flex items-center h-full">
              <MenuButton
                class="max-w-xs bg-gray-800 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span class="sr-only">Open user menu</span>
                <UserCircleIcon
                  class="h-8 w-8 text-gray-400"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem as="div">
                  <div
                    class="block px-4 py-2 text-sm text-gray-700 w-full text-center"
                  >
                    {{ user.email }}
                  </div>
                </MenuItem>
                <MenuItem as="div">
                  <button
                    @click.prevent="logout"
                    class="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 w-full"
                  >
                    Sign Out
                  </button>
                </MenuItem>
                <MenuItem
                  as="div"
                  v-for="item in userNavigation"
                  :key="item.name"
                  ><button
                    :href="item.href"
                    class="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 w-full"
                  >
                    <a :href="item.href"> {{ item.name }}</a>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="-mr-2 ml-2 flex xl:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="xl:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-2 text-gray-300">
        <router-link
          v-for="item in navigation"
          :key="item.name"
          :to="item.href"
          class="hover:bg-gray-700 hover:text-white block px-2 py-2 rounded-md text-base font-medium"
          active-class="bg-gray-700 text-white"
          >{{ item.name }}</router-link
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center pl-5">
          <div class="text-base font-medium leading-none text-white">
            {{ user.email }}
          </div>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <button
            v-if="!loading"
            @click.prevent="logout"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          >
            Sign Out
          </button>
          <a
            v-for="item in userNavigation"
            :key="item.name"
            :href="item.href"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >{{ item.name }}</a
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <!-- Spacer for fixed nav -->
  <div class="h-14 w-full"></div>
</template>

<script lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { useRouter } from 'vue-router';
import { MenuIcon, XIcon, SelectorIcon } from '@heroicons/vue/outline';
import { CheckIcon, UserCircleIcon } from '@heroicons/vue/solid';
import { ref, onMounted, watch } from 'vue';
import { computed, defineComponent, inject } from '@vue/runtime-core';
import store from '@/store';
import { AuthKey } from '@/symbols';
import { getTripsBySearch } from '@/api/trips.api';
import { Session, Trip } from '@/models';

// eslint-disable-next-line
const REPORT_RESTRICTED_USERS = ['travelperk'];

interface SearchResult {
  item: Trip | Session;
  resultType: 'trip' | 'session';
}

const navigation = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'Trip Explorer', href: '/trips' },
  { name: 'Organization', href: '/organization' },
  {
    name: 'User Management',
    href: '/users',
    validation_func: (user: Record<string, unknown>) =>
      (user?.roles as string[])?.includes('user_admin'),
  },
  {
    name: 'Post-Booking White Label', // Needs a shorter name
    href: '/whitelabel-postbooking',
    validation_func: (user: Record<string, unknown>) =>
      (user?.roles as string[])?.includes('white_label'),
  },
  {
    name: 'Billing',
    href: '/billing',
    validation_func: (user: Record<string, unknown>) =>
      (user?.roles as string[])?.includes('finance'),
  },
  { name: 'Support', href: '/support' },
];

const userNavigation = [
  { name: 'Change Password', href: '/change-password', euEnabled: false },
];

export default defineComponent({
  name: 'DashboardNav',
  components: {
    CheckIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    UserCircleIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    SelectorIcon,
  },
  inject: ['Auth'],
  methods: {
    logout: function (): void {
      // eslint-disable-next-line
      (this as any).Auth.logout();
      this.$router.push({ path: '/' });
    },
  },

  setup() {
    const router = useRouter();
    const auth = inject('Auth') as typeof AuthKey;

    const searchQuery = ref('');
    const searchResults = ref([] as SearchResult[]);
    const searchedTrips = ref([] as Trip[]);
    const searchedSessions = ref([] as Session[]);
    const searchLoading = ref(false);

    const error = ref('');
    const sharedState = store.state;
    const showReports = ref(
      sharedState.integration?.organization?.name === 'travelperk'
    );
    const user = sharedState.user;
    const euEnv = process.env.VUE_APP_EU_ENV;

    const filteredNavigation = computed(() =>
      navigation.filter((item) =>
        item?.validation_func ? item.validation_func(user) : true
      )
    );

    const filteredUserNavigation = computed(() =>
      userNavigation.filter((item) => item.euEnabled || !euEnv)
    );

    const searchResultsRef = ref(null);

    const search = async () => {
      try {
        searchLoading.value = true;
        clearSearchResults();
        if (searchQuery.value.length) {
          searchResults.value = (
            await getTripsBySearch(searchQuery.value)
          ).data.results;
          searchedTrips.value = searchResults.value
            .filter((searchResult) => searchResult.resultType === 'trip')
            .map((searchResult) => searchResult.item) as Trip[];
          searchedSessions.value = searchResults.value
            .filter((searchResult) => searchResult.resultType === 'session')
            .map((searchResult) => searchResult.item) as Session[];
        }
      } catch (e) {
        error.value = 'Error searching for results';
      } finally {
        searchLoading.value = false;
      }
    };

    const clearSearchResults = () => {
      searchResults.value = [];
      searchedTrips.value = [];
      searchedSessions.value = [];
      error.value = '';
    };

    const goToTrip = (trip_id: string) => {
      clearSearchResults();
      router.push({ path: `/trip/${trip_id}` });
    };

    const goToSession = (session_id: number) => {
      clearSearchResults();
      router.push({ path: `/session/${session_id}` });
    };

    onMounted(() => {
      // Needed for us to remove the bar on click (either external or internal)
      window.addEventListener('click', (_ev: MouseEvent) => {
        if (
          searchResults.value.length > 0 ||
          error.value ||
          searchLoading.value
        )
          clearSearchResults();
      });
    });

    return {
      ...auth,
      user,
      navigation: filteredNavigation,
      userNavigation: filteredUserNavigation,
      searchQuery,
      searchResults,
      searchedTrips,
      searchedSessions,
      sharedState,
      showReports,
      search,
      goToTrip,
      goToSession,
      searchLoading,
      error,
      searchResultsRef,
    };
  },
});
</script>
