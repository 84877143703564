<template>
  <div
    class="min-h-screen bg-gray-900 z-50 w-full text-white flex items-center justify-center"
    v-if="loading"
  >
    <img
      class="h-8"
      src="../assets/logo/gordian_white.svg"
      loading="eager"
      alt="Gordian Software logo"
    />
  </div>
  <div class="min-h-full" v-if="!loading">
    <DashboardNav />
    <main class="bg-gray-100 min-h-full">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import { watchEffect } from 'vue';
import { ref } from '@vue/reactivity';
import store from '@/store';
import { getAllIntegrations } from '@/api/integrations.api';
import { getSelfUser } from '@/api/users.api';
import DashboardNav from '@/components/DashboardNav.vue';
import { authPlugin } from '@/auth/index';

export default {
  name: 'AppLayoutDashboard',
  components: {
    DashboardNav,
  },
  setup(): Record<string, unknown> {
    const loading = ref(false);
    const sharedState = store.state;

    watchEffect(async () => {
      try {
        loading.value = true;
        const integrations = await getAllIntegrations();
        store.setIntegrations(integrations.data.integrations);

        const user = (await getSelfUser()).data;
        store.setUser(user);
        loading.value = false;
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    });

    return {
      loading,
      sharedState,
    };
  },
};
</script>
