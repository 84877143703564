<template>
  <div class="h-screen w-full bg-gray-900">
    <slot />
  </div>
</template>

<script lang="ts">
// import AppLayoutLinks from '@/layouts/AppLayoutLinks'
export default {
  name: 'AppLayoutAuth',
  components: {
    // AppLayoutLinks
  },
};
</script>
