import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { authPlugin } from '@/auth';
import toCamelCase from '@/utils/toCamelCase';
import toSnakeCase from '@/utils/toSnakeCase';

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.request.use(
  async (config) => {
    const token = await authPlugin.getTokenSilently({});
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (config.method !== 'get') {
      config.data = toSnakeCase(config.data);
    } else {
      config.params = toSnakeCase(config.params);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Transforms the python snake case keys in the response to js lower camel case standard
httpClient.interceptors.response.use(
  (config: AxiosResponse) => ({
    ...config,
    data: toCamelCase(config.data),
  }),
  (error) => Promise.reject(toCamelCase(error))
);

export default httpClient;
